import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DoublePage from 'components/Icons/DoublePage';
import UITooltip from 'components/Others/UITooltip';
import { CALL_CENTER_PHONE_NUMBER_BY_REGION } from 'constants/callCenter';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { clearNonNumeric } from 'utils/common.utils';
import footerData from '../../globalscripts/jsons/footerData.json';
import { addDataLayer } from '../../lib/ga';
import { RootState } from '../../redux/store';
import Facebook from '../Icons/Facebook';
import Instagram from '../Icons/Instagram';
import Linkedin from '../Icons/Linkedin';
import Twitter from '../Icons/Twitter';
import TextLink from '../TextLink/TextLink';
import axios from 'axios';
import { openAuth, setInitialAuthTab, setNewsletterGuest, setUser } from 'redux/features/auth/authSlice';
import ProductTag from 'components/Others/ProductTag';
import { getWelcomeCampaing } from 'constants/welcomeCampaing';

var checkDataLayerForNewsLetter = false;
var updateUser = true;

export default function Footer() {

  const isLogin = useSelector((state: RootState) => state.authEvent.isLogin);
  const selectedRegion = useSelector(
    (state: RootState) => state.regionEvent.selectedRegion
  );
  const country = useSelector(
    (state: RootState) => state.regionEvent.country
  );
  const user = useSelector((state: RootState) => state.authEvent.user);
  const authToken = useSelector(
    (state: RootState) => state.authEvent.authToken
  );
  const [value, copy] = useCopyToClipboard();

  const dispatch = useDispatch();
  const router = useRouter();

  const welcomeCampaign = useMemo(() => {
    return getWelcomeCampaing(router.locale!);
  }, [router]);

  const [footerLinks, setFooterLinls] = useState([
    {
      id: 2,
      text: 'Stores',
      link: 'stores'
    },
    {
      id: 3,
      text: 'Browse Our Products',
      link: 'shop'
    },
    {
      id: 4,
      text: 'Shipping And Returns',
      link: 'our-glasses/shipping-and-returns'
    },
    {
      id: 5,
      text: 'Warranty',
      link: 'our-glasses/warranty'
    },
    {
      id: 6,
      text: welcomeCampaign.title,
      link: welcomeCampaign.slug
    },
    {
      id: 7,
      text: <>Referral Program <span className='ml-2 mt-0.5'><ProductTag isSmall={true} text={'New'} isAbsolute={false} textColor="#fff" backgroundColor="#FF8200" /></span></>,
      link: 'referral-program'
    },
    {
      id: 8,
      text: <>Upgrade to Blue Light Filter</>,
      link: 'upgrade-blue-light-filter'
    }
  ]);

  const updateUserNewsletterSetting = () => {
    if (isLogin && updateUser) {
      updateUser = false;
      axios
        .get(process.env.NEXT_PUBLIC_LARAVEL_URL + '/me/setNewsletterEnable', {
          headers: {
            Authorization: 'Bearer ' + authToken //the token is a variable which holds the token
          }
        })
        .then(() => {
          var newUser = Object.assign({}, user);
          newUser['new_opt_in'] = 'ALL';
          newUser['channel_email'] = true;
          newUser['channel_sms'] = true;
          newUser['channel_phone'] = true;
          dispatch(setUser(newUser));
        });
    }
  };

  const dataLayerForNewsLetter = () => {
    if (!checkDataLayerForNewsLetter) {
      checkDataLayerForNewsLetter = true;
      addDataLayer({ event: 'newsletter_success' });
    }
    if (!isLogin) {
      dispatch(setNewsletterGuest(true));
    }
    updateUserNewsletterSetting();
  };

  const [newsLetterLoading, setNewsLetterLoading] = useState<boolean>(false);
  const [newsLetterSubmit, setNewsLetterSubmit] = useState<boolean>(false);

  const [newsLetterError, setNewsLetterError] = useState<boolean>(false);
  const [newsLetterMessage, setnewsLetterMessage] = useState<string>("");


  const subscribeNewsLetter = (data: any) => {
    setNewsLetterLoading(true);
    axios.post(process.env.NEXT_PUBLIC_LARAVEL_URL + '/campaigns/newsletter/create', {
      email: data.EMAIL,
      region: selectedRegion.key,
      regionValue: selectedRegion.value,
      country: country
    }).then((res: any) => {
      setNewsLetterLoading(false);
      setNewsLetterError(false);
      setnewsLetterMessage(res.data.message);
      setNewsLetterSubmit(true);
      dataLayerForNewsLetter();
    }).catch((e: any) => {
      setNewsLetterLoading(false);
      setNewsLetterError(true);
      setNewsLetterSubmit(true);
      setnewsLetterMessage(e.response.data.errors.email[0]);
    });
  }

  const currentYear = new Date().getFullYear();

  return (
    <footer className={cx("flex flex-col gap-[50px] w-full items-center justify-center pb-10 mdx:pb-8 pt-8",
      { 'pt-10': !(isLogin && (('order_count' in user && Number(user.order_count) == 0))) })}>

      {!(router.asPath.indexOf('/contact-lenses') > -1) && isLogin && (('order_count' in user && Number(user.order_count) == 0)) && (
        <div className="flex flex-col border-y-[1px] gap-[20px] w-full py-[75px] border-[#ECECEC] border-b-2 border-t-2 text-brand-black100 mt-[50px]">
          <div className="flex flex-1 flex-col justify-center items-center gap-[10px]">
            <p className="text-[24px] leading-6 text-center"> Enjoy <span className='font-semibold'> {getWelcomeCampaing(router.locale?.toLowerCase() ?? 'au').discount}% off</span> on your <span className='font-semibold'>first purchase!</span> </p>
            <div className="flex mt-[10px] justify-between items-center bg-[#D9F3F7] rounded-md p-[16px] w-[278px]">
              <p className="text-brand-grey450"> Discount code: </p>
              <div className="flex items-center justify-between w-1/2">
                <p> {welcomeCampaign.code} </p>
                <UITooltip id="copy-tooltip" place='top' messageWrapperClassName='whitespace-pre' message={value === welcomeCampaign.code ? 'Copied!' : '  Copy  '}>
                  <span className="cursor-pointer" onClick={() => copy(welcomeCampaign.code)}> <DoublePage /> </span>
                </UITooltip>
              </div>
            </div>
          </div>
        </div>
      )}

      {!(router.asPath.indexOf('/contact-lenses') > -1) && !isLogin && (
        <div className="flex flex-col border-y-[1px] gap-[20px] w-full py-[75px] border-[#ECECEC] border-b-2 border-t-2 text-brand-black100 mt-[50px]">
          <div className="flex justify-center sm:text-[22px] xsm:text-[18px] md:text-[24px] leading-7">
            <p className="px-[20px] text-center">Register today and get your <span className='font-semibold'>{getWelcomeCampaing(router.locale?.toLowerCase() ?? 'au').discount}% off on your first purchase!</span> </p>
          </div>
          <div className='relative flex justify-center items-center gap-[8px] xsmx:w-full flex-row' >
            <button
              onClick={() => {
                dispatch(openAuth());
                dispatch(setInitialAuthTab('SIGN_UP'));
              }}
              className={cx(
                'flex w-[150px] h-[48px] items-center justify-center rounded-md px-[18px] text-[14px] bg-brand-black100 font-medium transition-colors w-full text-white border-[2px] border-brand-black100 bg-white text-center hover:bg-white hover:text-brand-black100',
              )}>
              Create Account
            </button>
          </div>
        </div>
      )}

      <div className="flex h-full w-full max-w-[1224px] flex-col px-4">
        <div className="mb-28 flex items-start justify-between lgx:mb-16 lgx:flex-col lgx:items-center">
          {footerData ? (
            <div className="grid w-full grid-cols-2 items-start justify-between gap-[20px] lg:grid-cols-4">
              <div className="grid grid-flow-row items-start gap-[15px]">
                <h3 className="font-sans text-sm font-bold text-black">Shop</h3>
                {footerLinks.map((item) => {
                  if (item.id == 9 && (router.locale == 'nz' || router.locale == 'international')) return null;
                  // if (router.locale == 'ca' && item.text && item.text.toString().toLocaleLowerCase().includes('welcome')) return null;
                  return (<div key={item.id} className="group flex items-center">
                    <Link prefetch={false} href={`/${item.link}`}>

                      <span
                        className={`flex items-center font-sans text-sm font-normal`}>
                        {item.text}
                      </span>

                    </Link>
                  </div>)
                })}
              </div>
              <div className="grid grid-flow-row items-start gap-[15px]">
                <h3 className="font-sans text-sm font-bold text-black">
                  Our Glasses
                </h3>
                <div className="group flex items-center">
                  <Link prefetch={false} href={`/online-pupillary-distance`}>

                    <span
                      className={`flex items-center font-sans text-sm font-normal`}>
                      {`Online Pupil Distance Measurement Tool`}
                    </span>

                  </Link>
                </div>
                {footerData
                  .filter((el: any) => el.category === 'our-glasses')
                  .map(
                    (item: any) =>
                      !footerLinks.filter(
                        (e: any) => e.text === item.text
                      )[0] && !item.text.includes('Shipping') && (
                        <div key={item.id} className="group flex items-center">
                          <Link prefetch={false} href={`/${item.link}`}>
                            <span
                              className={`flex items-center font-sans text-sm font-normal`}>
                              {item.text}
                            </span>
                          </Link>
                        </div>
                      )
                  )}
                {['ca', 'au'].includes(router.locale as string) && (
                  <div className="group flex items-center">
                    <Link prefetch={false} href="/eye-health/health-funds">

                      <span
                        className={`flex items-center font-sans text-sm font-normal`}>
                        Health Funds
                      </span>

                    </Link>
                  </div>
                )}
                <div className="group flex items-center">
                  <Link prefetch={false} href="/eye-health/glossary">

                    <span
                      className={`flex items-center font-sans text-sm font-normal`}>
                      Glossary
                    </span>

                  </Link>
                </div>
                <div className="group flex items-center">
                  <Link prefetch={false} href={['ca', 'nz'].includes(router.locale as string) ? '/progressives' : '/multifocals'}>

                    <span
                      className={`flex items-center font-sans text-sm font-normal`}>
                      {['ca', 'nz'].includes(router.locale as string) ? 'Progressives' : 'Multifocals'}
                    </span>

                  </Link>
                </div>
              </div>

              <div className="grid grid-flow-row items-start gap-[15px]">
                <h3 className="font-sans text-sm font-bold text-black">
                  About Us
                </h3>

                <div className="group flex items-center">
                  <Link prefetch={false} href="/blog">

                    <span
                      className={`flex items-center font-sans text-sm font-normal`}>
                      Blog
                    </span>

                  </Link>
                </div>
                {footerData
                  .filter((el: any) => el.category === 'about-us')
                  .map(
                    (item: any) =>
                      (!footerLinks.filter(
                        (e: any) => e.text === item.text
                      )[0]) && item.text !== 'FAQ' && (
                        <div key={item.id} className="group flex items-center">
                          <Link prefetch={false} href={`/${item.link}`}>
                            <span
                              className={`flex items-center font-sans text-sm font-normal`}>
                              {item.text}
                            </span>
                          </Link>
                        </div>
                      )
                  )}
                {(router.locale === 'ca') && (
                  <div className="group flex items-center">
                    <Link prefetch={false} href="/career">

                      <span
                        className={`flex items-center font-sans text-sm font-normal`}>
                        Careers
                      </span>

                    </Link>
                  </div>)}

              </div>
              <div className="grid grid-flow-row items-start gap-[15px]">
                <h3 className="font-sans text-sm font-bold text-black">
                  Help
                </h3>

                <div className="group flex items-center">
                  {router.locale === 'ca' ? (
                    <a href="mailto:hello@ca.dresden.vision">
                      <span className="flex items-center font-sans text-sm font-normal">
                        hello@ca.dresden.vision
                      </span>
                    </a>
                  ) : (
                    <a href="mailto:hello@dresden.vision">
                      <span className="flex items-center font-sans text-sm font-normal">
                        hello@dresden.vision
                      </span>
                    </a>
                  )}
                </div>

                {(router.locale === 'au' ||
                  router.locale === 'nz' ||
                  router.locale === 'ca') && (<div className="group flex items-center">

                    <span className="flex items-center font-sans text-sm font-normal">
                      <a href={'tel:' + clearNonNumeric(CALL_CENTER_PHONE_NUMBER_BY_REGION[router.locale])}>
                        {CALL_CENTER_PHONE_NUMBER_BY_REGION[router.locale]}
                      </a>
                    </span>
                  </div>)}

                <div className="group flex items-center">
                  <a className="flex items-center font-sans text-sm font-normal cursor-pointer" onClick={() => {
                    try {
                      if (window) {
                        //@ts-ignore
                        window.zE("messenger", "open")
                      }
                    } catch (e) {
                      console.log("zendesk not loaded")
                    }
                  }}>
                    Talk with an agent
                  </a>
                </div>
                <div className="group flex items-center">
                  <Link href="/contact-us" className="flex items-center font-sans text-sm font-normal cursor-pointer" >
                    Contact Us
                  </Link>
                </div>

                <div className="group flex items-center">
                  <Link href="/about-us/faq" className="flex items-center font-sans text-sm font-normal cursor-pointer" >
                    FAQ
                  </Link>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex items-center justify-between mdx:flex-col">
          <span className="select-none font-sans text-sm font-medium text-brand-grey mdx:mb-4">
            © {currentYear} Dresden Vision
          </span>
          <div className="flex items-center mdx:flex-col-reverse">
            <ul className="grid grid-cols-4 gap-x-6">
              <li>
                <a
                  target="_blank"
                  aria-label="Go to Dresden Vision Twitter"
                  href="https://twitter.com/dresdenvision">
                  <Twitter />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  aria-label="Go to Dresden Vision Linkedin"
                  href="https://www.linkedin.com/company/dresdenvision/mycompany/">
                  <Linkedin />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  aria-label="Go to Dresden Vision Instagram"
                  href={
                    router.locale === 'ca'
                      ? 'https://www.instagram.com/dresdenvisionca/'
                      : 'https://www.instagram.com/dresdenvision/'
                  }>
                  <Instagram />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  aria-label="Go to Dresden Vision Facebook"
                  href={
                    router.locale === 'ca'
                      ? 'https://www.facebook.com/dresdenvisioncanada'
                      : 'https://www.facebook.com/dresdenvision'
                  }>
                  <Facebook />
                </a>
              </li>
            </ul>

            <ul className="flex items-center mdx:mb-8">
              <li className="ml-9 mdx:ml-0">
                <TextLink
                  linkTo="privacy"
                  className="select-none font-sans text-sm font-medium text-brand-grey">
                  Privacy
                </TextLink>
              </li>
              <li className="ml-9">
                <TextLink
                  linkTo="terms-and-conditions"
                  className="select-none font-sans text-sm font-medium text-brand-grey">
                  Terms & Conditions
                </TextLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

const NewsletterSuccess = () => {
  const router = useRouter();

  const discountCode = useMemo(() => {
    return getWelcomeCampaing(router.locale!);
  }, [router]);
  const [value, copy] = useCopyToClipboard();

  return (
    <div className="flex flex-1 flex-col justify-center items-center gap-[10px]">
      <p className="text-[24px] leading-4 font-semibold"> Welcome to our community! </p>
      <p className=""> Use your 5% discount on your first order </p>
      <div className="flex mt-[10px] justify-between items-center bg-[#D9F3F7] rounded-md p-[16px] w-[278px]">
        <p className="text-brand-grey450"> Discount code: </p>
        <div className="flex items-center justify-between w-1/2">
          <p> {discountCode.code} </p>
          <UITooltip id="copy-tooltip" place='top' messageWrapperClassName='whitespace-pre' message={value === discountCode.code ? 'Copied!' : '  Copy  '}>
            <span className="cursor-pointer" onClick={() => copy(discountCode.code)}> <DoublePage /> </span>
          </UITooltip>
        </div>
      </div>
    </div>
  )
}